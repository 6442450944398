@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Design_System';
  src: url('assets/fonts/7de806d54e9b576def200fd066de595a.eot');
  src: url("assets/fonts/7de806d54e9b576def200fd066de595a.woff2") format('woff2'),
  url("assets/fonts/7de806d54e9b576def200fd066de595a.woff") format('woff'),
  url("assets/fonts/7de806d54e9b576def200fd066de595a.ttf") format('truetype'),
  url("assets/fonts/7de806d54e9b576def200fd066de595a.svg") format('svg');
}

html, body, app-root {
  margin: 0;
  min-height: 100vh;
  display: block;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.mdc-notched-outline__notch {
  border-right: none;
}
